import {Component} from '@angular/core';

interface FaqEntry {
  question: string;
  answer: string;
}

@Component({
  selector: 'app-faq-section',
  templateUrl: './faq-section.component.html',
  styleUrl: './faq-section.component.scss'
})
export class FaqSectionComponent {

  protected readonly faqEntries: Array<FaqEntry> = [
    {
      question: 'Jak wygląda pierwsza wizyta u fizjoterapeuty?',
      answer: `Pierwsza wizyta w moim gabinecie w Gliwicach to kompleksowa konsultacja. Składa się z rozmowy na temat
               Twoich dolegliwości, analizy dokumentacji medycznej oraz badania funkcjonalnego, ortopedycznego lub
               neurologicznego. Dzięki temu mogę dokładnie ocenić problem i opracować plan leczenia dostosowany do
               Twoich potrzeb.`
    },
    {
      question: 'Ile kosztuje wizyta u fizjoterapeuty?',
      answer: `Cena wizyty zależy od rodzaju terapii i jej długości. W moim gabinecie oferuję konkurencyjne ceny oraz
               indywidualne podejście do każdego pacjenta. Szczegółowy cennik znajdziesz po kliknięciu przycisku Booksy
               lub możesz zapytać telefonicznie.`
    },
    {
      question: 'Kiedy warto udać się do fizjoterapeuty?',
      answer: `Fizjoterapeuta może pomóc przy wielu dolegliwościach, takich jak ból pleców, rwa kulszowa, urazy
               sportowe, ograniczenia ruchowe czy rehabilitacja po operacji. Jeśli odczuwasz ból lub dyskomfort, nie
               zwlekaj – im szybciej zaczniemy terapię, tym lepsze efekty możemy osiągnąć.`
    },
    {
      question: 'Czy potrzebuję skierowania do fizjoterapeuty?',
      answer: `W moim gabinecie w Gliwicach nie potrzebujesz skierowania – możesz umówić się na wizytę bezpośrednio.
               Jeśli jednak posiadasz dokumentację medyczną (np. wyniki badań obrazowych), warto ją zabrać, by lepiej
               dopasować terapię.`
    },
    {
      question: 'Jak długo trwa rehabilitacja u fizjoterapeuty?',
      answer: `Czas rehabilitacji zależy od rodzaju problemu, stopnia jego zaawansowania oraz Twoich indywidualnych
               potrzeb. Zazwyczaj leczenie obejmuje kilka wizyt, ale dokładny plan ustalimy podczas pierwszej
               konsultacji.`
    },
    {
      question: 'Czy fizjoterapia jest bolesna?',
      answer: `Terapia jest dostosowana do Twojej tolerancji bólu. Niektóre techniki mogą być odczuwalne, ale zawsze
               dbam o to, by pacjenci czuli się komfortowo. Moim celem jest ulga w bólu, a nie jego nasilenie.`
    },
    {
      question: 'Jakie dokumenty zabrać na wizytę u fizjoterapeuty?',
      answer: `Warto przynieść wyniki badań obrazowych (np. RTG, MRI) oraz wcześniejsze diagnozy, jeśli je posiadasz.
               Przydatny będzie też wygodny strój umożliwiający swobodny dostęp do problematycznych obszarów ciała.`
    },
    {
      question: 'Czy fizjoterapia pomaga na specyficzne dolegliwości, np. ból pleców, rwę kulszową?',
      answer: `Tak, fizjoterapia skutecznie pomaga w leczeniu wielu dolegliwości, w tym bólu kręgosłupa, rwy kulszowej,
               urazów sportowych czy problemów ze stawami. Dzięki mojemu doświadczeniu zarówno w pracy ze sportowcami,
               jak i pacjentami indywidualnymi, oferuję metody leczenia dopasowane do każdej sytuacji.`
    }
  ]

}
