<div class="section section-dark" id="pierwsza-wizyta">
  <div class="container">
    <h1 class="title text-center">Pierwsza wizyta</h1>
    <hr class="section-hr reveal fade-width"/>
    <div class="row">
      <div class="col-lg-12">
        <h3 class="description text-justify-lg text-center pb-3">
          <b>Pierwsza wizyta u fizjoterapeuty to Twój pierwszy krok do zdrowia.</b><br/>
        </h3>
        <p class="description text-justify">
          Rozumiem, że możesz czuć się niepewnie przed wizytą, dlatego stworzyłem przyjazne i komfortowe miejsce w moim
          gabinecie w Gliwicach, gdzie poczujesz się zaopiekowany/a. Oto, czego możesz się spodziewać:
        </p>
        <br/>
        <h3 class="description text-justify pb-3">
          <b>Co zabrać na wizytę?</b>
        </h3>
        <ul class="description text-justify">
          <li>
            <b>wygodny strój</b>, np. koszulka i spodenki, umożliwiające swobodny dostęp do obszaru problematycznego,
          </li>
          <li>
            <b>dokumentację medyczną</b>, w tym wyniki badań obrazowych (RTG, MRI) lub wcześniejsze diagnozy,
          </li>
          <li>
            jeśli nie masz dokumentów, to nie problem – badanie funkcjonalne pozwoli mi ocenić Twój stan zdrowia.
          </li>
        </ul>
        <br/>
        <h3 class="description text-justify pb-3">
          <b>Jak przebiega wizyta?</b>
        </h3>
        <ol class="description">
          <li>
            <b>Szczegółowy wywiad medyczny</b>
            <span class="md-inline text-justify">
              – omówimy Twoje dolegliwości, styl życia i cele terapeutyczne.
            </span>
            <span class="sm-inline text-justify">
              <br/>Omówimy Twoje dolegliwości, styl życia i cele terapeutyczne.
            </span>
          </li>
          <li>
            <b>Badanie fizjoterapeutyczne</b>
            <span class="md-inline text-justify">
              – obejmuje testy funkcjonalne, ortopedyczne i neurologiczne, dopasowane do Twoich potrzeb.
            </span>
            <span class="sm-inline text-justify">
              <br/>Obejmuje testy funkcjonalne, ortopedyczne i neurologiczne, dopasowane do Twoich potrzeb.
            </span>
          </li>
          <li>
            <b>Plan terapii</b>
            <span class="md-inline text-justify">
               – opracowujemy indywidualny program leczenia i odpowiem na wszystkie Twoje pytania. Jeśli Twój przypadek
              wymaga konsultacji z innym specjalistą, pomogę Ci znaleźć odpowiedniego lekarza.
            </span>
            <span class="sm-inline text-justify">
              <br/>Opracowujemy indywidualny program leczenia i odpowiem na wszystkie Twoje pytania. Jeśli Twój
              przypadek wymaga konsultacji z innym specjalistą, pomogę Ci znaleźć odpowiedniego lekarza.
            </span>
          </li>
        </ol>
        <br/>
        <h3 class="description text-justify-lg text-center pb-3">
          <b>Dlaczego warto wybrać mój gabinet w Gliwicach?</b>
        </h3>
        <p class="description text-justify">
          Mój gabinet w Gliwicach oferuje indywidualne podejście, nowoczesne metody fizjoterapii i wieloletnie
          doświadczenie w leczeniu takich problemów jak bóle kręgosłupa, urazy sportowe czy rehabilitacja po operacjach.
        </p>
        <br/>
      </div>
    </div>
  </div>
</div>
