import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {EmailMessage, EmailService} from "../../services/email.service";
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-email-message-section',
  templateUrl: './email-message-section.component.html',
  styleUrl: './email-message-section.component.scss'
})
export class EmailMessageSectionComponent {

  private readonly emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  private readonly emailErrorDescriptions = new Map<string, string>([
    ['required', 'Podaj swój adres e-mail.'],
    ['email', 'Podaj poprawny adres e-mail.']
  ]);

  private readonly nameFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(100)
  ]);
  private readonly nameErrorDescriptions = new Map<string, string>([
    ['required', 'Podaj swoje imię i nazwisko.'],
    ['minlength', 'Imię i nazwisko jest zbyt krótkie.'],
    ['maxlength', 'Imię i nazwisko jest zbyt długie.']
  ]);

  private readonly messageFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(2000)
  ]);
  private readonly messageErrorDescriptions = new Map<string, string>([
    ['required', 'Podaj treść wiadomości.'],
    ['minlength', 'Wiadomość jest zbyt krótka.'],
    ['maxlength', 'Wiadomość jest zbyt długa.']
  ]);

  private readonly dataProcessingFormControl = new FormControl(false, [
    Validators.requiredTrue
  ]);
  private readonly dataProcessingErrorDescriptions = new Map<string, string>([
    ['required', 'Zaakceptuj zgodę na przetwarzanie danych.']
  ]);

  readonly emailContactForm = new FormGroup({
    email: this.emailFormControl,
    name: this.nameFormControl,
    message: this.messageFormControl,
    dataProcessingConsent: this.dataProcessingFormControl
  })

  sendEmailInProgress = false;

  constructor(private readonly emailService: EmailService,
              private readonly toastService: ToastService) {
  }

  get emailError(): string {
    return EmailMessageSectionComponent.getFormControlError(this.emailFormControl, this.emailErrorDescriptions);
  }

  get nameError(): string {
    return EmailMessageSectionComponent.getFormControlError(this.nameFormControl, this.nameErrorDescriptions);
  }

  get messageError(): string {
    return EmailMessageSectionComponent.getFormControlError(this.messageFormControl, this.messageErrorDescriptions);
  }

  get dataProcessingError(): string {
    return EmailMessageSectionComponent.getFormControlError(this.dataProcessingFormControl, this.dataProcessingErrorDescriptions);
  }

  private static getFormControlError(formControl: FormControl, errorMessages: Map<string, string>): string {
    if (formControl.invalid && (formControl.dirty || formControl.touched)) {
      for (const key of errorMessages.keys()) {
        if (formControl.hasError(key)) {
          return errorMessages.get(key)!;
        }
      }
    }
    return '';
  }

  submitEmailMessage() {
    this.emailContactForm.markAllAsTouched();
    if (this.emailContactForm.invalid || this.sendEmailInProgress) {
      return;
    }
    this.sendEmailInProgress = true;
    const formValue = this.emailContactForm.value;
    const message: EmailMessage = {
      to: 'physiomove.strug@gmail.com',
      replyTo: formValue.email!,
      subject: 'Fizjoterapiastrug.pl - wiadomość od klienta',
      text: `${formValue.message}\n\n${formValue.name}`
    };
    this.emailService.sendEmail(message)
      .then(() => {
          this.emailContactForm.reset();
          this.toastService.show({
            header: 'Wiadomość email',
            message: 'Wiadomość została wysłana.',
            delay: 5000,
            classname: 'success'
          });
          this.sendEmailInProgress = false;
        },
        error => {
          console.log(error);
          this.toastService.show({
            header: 'Wiadomość email',
            message: 'Wysyłka wiadomości email nie powiodła się.',
            delay: 15000,
            classname: 'error'
          });
          this.sendEmailInProgress = false;
        });
  }
}
