<div class="section section-dark" id="pytania">
  <div class="container">
    <div class="text-center">
      <h1 class="title">Najczęściej zadawane pytania</h1>
      <hr class="section-hr reveal fade-width"/>
    </div>
    <div class="row">
      <div ngbAccordion [closeOthers]="true">
        <div ngbAccordionItem *ngFor="let entry of faqEntries">
          <div ngbAccordionHeader aria-level="1">
            <button ngbAccordionButton>
              <b>{{ entry.question }}</b>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <p class="text-justify">
                  {{ entry.answer }}
                </p>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
