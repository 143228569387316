<div class="section section-dark contact-background" id="kontakt">
  <div class="container">
    <h1 class="title text-center">Kontakt</h1>
    <hr class="section-hr reveal fade-width"/>
    <div class="row">
      <div class="col-lg-6">
        <a class="nav-link contact-link" rel="tooltip" title="Telefon" href="tel:{{constants.PHONE_NUMBER}}">
          <h2>
            <i class="description">
              <fa-icon [icon]="faPhone"></fa-icon>
            </i>
            <span class="description">519-403-519</span>
          </h2>
        </a>
        <a class="nav-link contact-link" rel="tooltip" title="E-mail" href="mailto:{{constants.EMAIL}}">
          <h2>
            <i class="description">
              <fa-icon [icon]="faAt"></fa-icon>
            </i>
            <span class="description">physiomove.strug&#64;gmail.com</span>
          </h2>
        </a>
        <a class="nav-link contact-link" rel="tooltip" title="Adres" href="{{constants.ADDRESS_LINK}}" target="_blank">
          <h2>
            <i class="description">
              <fa-icon [icon]="faMapLocationDot"></fa-icon>
            </i>
            <span class="description">{{ constants.ADDRESS_LONG_TEXT }}</span>
          </h2>
        </a>
      </div>
      <div class="col-lg-6">
        <h2 class="description text-lg-start text-center">
          Wizyty umawiane są telefonicznie lub poprzez platformę Booksy.
          <br/>
          W razie jakichkolwiek pytań zapraszam do kontaktu.
        </h2>
      </div>
    </div>
    <br/><br/>
    <div class="row">
      <div class="col-lg-12">
        <div class="leaflet" leaflet [leafletOptions]="mapOptions"></div>
      </div>
    </div>
    <div class="row text-center text-lg-end privacy-policy description contact-link">
      <a class="description" routerLink="{{'/' + ROUTES.PRIVACY_POLICY}}" target="_blank">
        Polityka prywatności
      </a>
    </div>
  </div>
</div>
