<div class="section section-dark" id="galeria">
  <div class="container text-center">
    <h1 class="title">Galeria</h1>
    <hr class="section-hr reveal fade-width"/>
    <ngb-carousel role="group" aria-roledescription="carousel">
      <ng-template ngbSlide *ngFor="let id of [1,2,3,4,5,6,7] as ids; index as i">
        <div class="row w-100">
          <div class="col-lg-6">
            <img ngSrc="assets/gallery/galeria{{previousImageId(i, ids.length)}}.webp"
                 alt="Galeria {{previousImageId(i, ids.length)}}" class="img-fluid" width="496" height="590">
          </div>
          <div class="col-lg-6">
            <img ngSrc="assets/gallery/galeria{{id}}.webp" alt="Galeria {{id}}" class="img-fluid" width="496"
                 height="590">
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
