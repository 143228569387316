import {Component} from '@angular/core';
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  readonly title = 'fizjo-strug';

  constructor() {
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);
  }

}
