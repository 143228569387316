<app-navbar [complex]="true"></app-navbar>
<div class="page-header section-dark main-background"></div>
<ngb-toast [header]="toastData.title" class="info start-50 translate-middle-x" [hidden]="!toastData.active"
           [autohide]="false">
  <span class="description">
    {{ toastData.details }}
  </span>
</ngb-toast>
<img class="booksy-widget clickable" ngSrc="assets/booksy-custom.svg" alt="Widżet Booksy" height="80" width="80"
     (click)="openBooksyDialog()" priority>
<app-toast-container></app-toast-container>
<app-about-me-section></app-about-me-section>
<app-first-visit-section></app-first-visit-section>
<app-services-section></app-services-section>
<app-gallery-section></app-gallery-section>
<app-reviews-section></app-reviews-section>
<app-faq-section></app-faq-section>
<app-email-message-section></app-email-message-section>
<app-contact-section></app-contact-section>
<app-footer></app-footer>
