<div class="section">
  <div class="container">
    <h1 class="title text-center">Masz pytania?<br/>Skontaktuj się!</h1>
    <hr class="section-hr reveal fade-width"/>
    <div class="row">
      <div class="col-lg-2">
      </div>
      <div class="col-lg-8">
        <form novalidate [formGroup]="emailContactForm" (ngSubmit)="submitEmailMessage()">
          <div class="form-group">
            <label for="email" class="description">Adres e-mail</label>
            <input type="email" class="form-control form-control-lg" id="email" placeholder="Adres e-mail"
                   formControlName="email" required>
            <div class="error-feedback">
              <span>{{ emailError }}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="name" class="description">Imię i nazwisko</label>
            <input type="text" class="form-control form-control-lg" id="name" placeholder="Imię i nazwisko"
                   formControlName="name" required>
            <div class="error-feedback">
              <span>{{ nameError }}</span>
            </div>
          </div>
          <div class="form-group">
            <label for="message" class="description">Wiadomość</label>
            <textarea class="form-control form-control-lg" id="message" placeholder="Wiadomość" rows="5"
                      formControlName="message" required>
            </textarea>
            <div class="error-feedback">
              <span>{{ messageError }}</span>
            </div>
          </div>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="data-processing-check"
                   formControlName="dataProcessingConsent" required>
            <label class="form-check-label description text-justify" for="data-processing-check">
              Wyrażam zgodę na przetwarzanie moich danych osobowych w postaci imienia, nazwiska i adresu e-mail podanych
              w powyższym formularzu, zgodnie z przepisami rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679
              z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych,
              w celu udzielenia odpowiedzi na złożone zapytanie.
            </label>
            <div class="error-feedback">
              <span>{{ dataProcessingError }}</span>
            </div>
          </div>
          <div class="form-group text-center">
            <button type="submit" class="btn btn-primary"
                    [disabled]="sendEmailInProgress">
              <span class="p-4" *ngIf="!sendEmailInProgress">Wyślij</span>
              <span class="spinner-border align-middle p-4 m-2" aria-hidden="true" *ngIf="sendEmailInProgress"></span>
              <span class="visually-hidden" role="status">Wysyłanie wiadomości...</span>
            </button>
          </div>
        </form>
      </div>
      <div class="col-lg-2">
      </div>
    </div>
  </div>
</div>
