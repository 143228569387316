<app-navbar [complex]="false"></app-navbar>
<div class="section">
  <div class="container px-5">
    <h1 class="title text-center">Polityka prywatności</h1>
    <ol type="I">
      <li class="h2">
        <h2>DEFINICJE</h2>
        <ol>
          <li>
            <b>Administrator</b> - administratorem danych osobowych Użytkowników Serwisu jest:<br/>
            PhysioMove Przemysław Strug,<br/>
            adres: ul. Wojciecha Korfantego 11/19a, 44-100 Gliwice,<br/>
            NIP: 6312686743, REGON: 383967231
          </li>
          <li>
            <b>Dane osobowe</b> - informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej
            (osobie, której dane dotyczą).
          </li>
          <li>
            <b>Osoba, której dane dotyczą</b> - każda osoba fizyczna, której dane osobowe przetwarzane są przez
            Administratora w związku z prowadzona przez niego działalnością np. osoba, z którą wiąże kontrakt umowny z
            Administratorem lub kierująca do niego zapytanie w formie e-maila.
          </li>
          <li>
            <b>Pliki Cookies</b> - dane informatyczne, które przechowywane są w urządzeniu końcowym, zawierające dane o
            korzystaniu przez Użytkownika z Serwisu.
          </li>
          <li>
            <b>Polityka</b> - niniejsza Polityka przetwarzania danych osobowych w Serwisie.
          </li>
          <li>
            <b>RODO</b> - rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
            sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
            takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).
          </li>
          <li>
            <b>Serwis</b> - witryna internetowa fizjoterapiastrug.pl.
          </li>
          <li>
            <b>Użytkownik</b> - każda osoba fizyczna lub prawna korzystająca z funkcjonalności Serwisu.
          </li>
        </ol>
      </li>

      <li class="h2">
        <h2>INFORMACJE OGÓLNE</h2>
        <ol>
          <li>
            Niniejsza Polityka Prywatności określa zasady przetwarzania danych osobowych pozyskanych za pośrednictwem
            Serwisu.
          </li>
          <li>
            Administrator jest Administratorem Twoich danych osobowych w odniesieniu do danych podanych dobrowolnie w
            Serwisie.
          </li>
          <li>
            Serwis wykorzystuje dane osobowe w następujących celach:
            <ol type="a">
              <li>zapewnienia dostępu do serwisu,</li>
              <li>korzystania z formularza kontaktowego,</li>
              <li>dostarczenie oferty lub informacji o usłudze.</li>
            </ol>
          </li>
          <li>
            Właścicielem Serwisu jest: PhysioMove Przemysław Strug, adres: ul. Wojciecha Korfantego 11/19a, 44-100
            Gliwice, NIP: 6312686743, REGON: 383967231.
          </li>
          <li>
            Dane osobowe zbierane przez Administratora za pośrednictwem Serwisu są przetwarzane zgodnie z
            Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony
            osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych
            oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), zwane również „RODO”, ustawą o
            ochronie danych osobowych z dnia 10 maja 2018 roku, ustawą – Prawo telekomunikacyjne z dnia 16 lipca 2004
            roku oraz ustawą o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 roku.
          </li>
          <li>
            Niniejszy dokument jest wyrazem dbałości o prawa osób odwiedzających Serwis znajdujący się w domenach firmy
            oraz korzystających z usług oferowanych za jego pośrednictwem. Korzystając z Serwisu Użytkownik potwierdza
            akceptację niniejszych warunków.
          </li>
        </ol>
      </li>

      <li class="h2">
        <h2>WYBRANE METODY OCHRONY DANYCH STOSOWANE PRZEZ ADMINISTRATORA</h2>
        <ol>
          <li>
            Miejsca wprowadzania danych osobowych są chronione w warstwie transmisji (certyfikat SSL). Dzięki temu dane
            osobowe wprowadzone na stronie zostają zaszyfrowane w komputerze użytkownika i mogą być odczytane jedynie na
            docelowym serwerze.
          </li>
          <li>
            Administrator okresowo zmienia swoje hasła administracyjne.
          </li>
          <li>
            W celu ochrony danych Administrator regularnie wykonuje kopie bezpieczeństwa.
          </li>
          <li>
            Istotnym elementem ochrony danych jest regularna aktualizacja wszelkiego oprogramowania, wykorzystywanego
            przez Administratora do przetwarzania danych osobowych, co w szczególności oznacza regularne aktualizacje
            komponentów programistycznych.
          </li>
        </ol>
      </li>

      <li class="h2">
        <h2>TWOJE PRAWA I DODATKOWE INFORMACJE O SPOSOBIE WYKORZYSTANIA DANYCH</h2>
        <ol>
          <li>
            Dane osobowe Użytkownika mogą być przekazywane dostawcom usług, z których korzysta Administrator przy
            prowadzeniu
            Serwisu. Dostawcy usług, którym przekazywane są dane osobowe, w zależności od uzgodnień umownych i
            okoliczności, albo podlegają poleceniom Spółki co do celów i sposobów przetwarzania tych danych (podmioty
            przetwarzające) albo samodzielnie określają cele i sposoby ich przetwarzania (administratorzy).
          </li>
          <li>
            Administrator korzysta m.in. z dostawców usług technicznych, którzy mogą obsługiwać infrastrukturę
            techniczną
            potrzebną do świadczenia usług portalu, w szczególności dostawców, którzy hostują, przechowują i utrzymują
            witrynę, jej zawartość i przetwarzane dane.
          </li>
          <li>
            Przysługuje Ci prawo żądania od Administratora:
            <ol type="a">
              <li>dostępu do danych osobowych Ciebie dotyczących,</li>
              <li>sprostowania danych,</li>
              <li>usunięcia danych,</li>
              <li>ograniczenia przetwarzania danych,</li>
              <li>przenoszenia danych,</li>
              <li>sprzeciwu,</li>
              <li>
                niepodlegania decyzji, która opiera się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu,
                i wywołuje wobec tej osoby skutki prawne lub w podobny sposób istotnie na nią wpływa.
              </li>
            </ol>
          </li>
          <li>
            Na działania Administratora przysługuje skarga do Prezesa Urzędu Ochrony Danych Osobowych.
          </li>
        </ol>
      </li>

      <li class="h2">
        <h2>OKRES PRZETWARZANIA DANYCH OSOBOWYCH</h2>
        <ol>
          <li>
            Twoje dane osobowe będą przetwarzane przez Administratora nie dłużej, niż jest to konieczne do wykonania
            związanych z nimi czynności określonych osobnymi przepisami (np. o prowadzeniu rachunkowości).
          </li>
          <li>
            Okres przetwarzania danych przez Administratora zależy od celu przetwarzania:
            <ol type="a">
              <li>
                Umowa: w przypadku, gdy podstawę przetwarzania stanowi niezbędność do zawarcia i wykonania umowy, dane
                osobowe będą przetwarzane do momentu jej zakończenia.
              </li>
              <li>
                Zgoda: jeśli przetwarzanie odbywa się na podstawie zgody, dane osobowe przetwarzane są do jej wycofania.
                Cofnięcie zgody nie wpływa jednak na zgodność z prawem przetwarzania, którego dokonano na podstawie
                zgody przed jej wycofaniem.
              </li>
              <li>
                Przepis prawa: w wypadku, gdy podstawą prawną jest przepis prawa okres przetwarzania danych osobowych
                wynika również z konkretnych przepisów.
              </li>
              <li>
                Uzasadniony interes Administratora: w przypadku przetwarzania danych na podstawie prawnie uzasadnionego
                interesu Administratorów dane osobowe przetwarzane są przez okres umożliwiający jego realizację lub do
                zgłoszenia skutecznego sprzeciwu względem przetwarzania danych.
              </li>
              <li>
                Ochrona przed roszczeniami: okres przetwarzania danych może być przedłużony w przypadku, gdy
                przetwarzanie jest niezbędne do ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami, a po
                tym okresie, jedynie w przypadku i w zakresie, w jakim będą wymagać tego przepisy prawa.
              </li>
            </ol>
          </li>
          <li>
            W wypadku upłynięcia okresu retencji dane osobowe są niezwłocznie usuwane lub anonimizowane.
          </li>
        </ol>
      </li>

      <li class="h2">
        <h2>INFORMACJE O FORMULARZACH</h2>
        <ol>
          <li>
            Serwis zbiera informacje podane dobrowolnie przez użytkownika, w tym dane osobowe, o ile zostaną one podane.
          </li>
          <li>
            Serwis może zapisać informacje o parametrach połączenia (np. oznaczenie czasu, adres IP).
          </li>
          <li>
            Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza, np. w celu
            dokonania procesu obsługi zgłoszenia serwisowego lub kontaktu handlowego, rejestracji usług, rezerwacji itp.
          </li>
        </ol>
      </li>

      <li class="h2">
        <h2>CELE ORAZ PODSTAWY PRAWNE PRZETWARZANIA DANYCH</h2>
        <ol>
          <li>
            Dane osobowe Użytkowników są przetwarzane w następujących celach:
            <ol type="a">
              <li>
                korzystania z formularza kontaktowego, na podstawie art. 6 ust. 1 pkt a) RODO – osoba, której dane
                dotyczą wyraziła zgodę na przetwarzanie swoich danych osobowych w celu przedstawienia oferty
                przegotowanej w oparciu o dane wskazane w formularzu. W przypadku korzystania z formularza kontaktowego
                w Serwisie, Użytkownik podaje: imię, nazwisko i adres e-mail.
              </li>
              <li>
                marketingu bezpośredniego, na podstawie art. 6 ust. 1 pkt f) RODO – prawnie uzasadnionego interesu
                Administratora polegającego na pozyskiwaniu nowych Użytkowników i zachęcaniu zakupu produktów i usług.
                Działania marketingowe wobec nowych Użytkowników są prowadzone po uprzednim wyrażeniu zgody na
                komunikację co najmniej jednym kanałem kontaktu, tj. e-mail lub telefon.
              </li>
              <li>
                ustalanie, dochodzenie i egzekucji roszczeń. W takim przypadku mogą być przetwarzane niektóre dane
                osobowe podane przez Użytkownika w ramach korzystania z funkcjonalności w Serwisie takie jak: imię,
                nazwisko, dane dotyczące korzystania z usług, jeżeli roszczenia wynikają ze sposobu, w jaki Użytkownik
                korzysta z usług, inne dane niezbędne do udowodnienia istnienia roszczenia, w tym rozmiarów poniesionej
                szkody. Podstawa prawna – prawnie uzasadniony interes (art. 6 ust. 1 lit. f RODO), polegający na
                ustaleniu, dochodzeniu i egzekucji roszczeń oraz na obronie przed roszczeniami w postępowaniu przed
                sądami i innymi organami państwowymi rekrutacji.
              </li>
            </ol>
          </li>
          <li>
            Administrator zapewnia by ilość przetwarzanych danych w korespondencji była zgodna z zasadą minimalizacji
            danych
            i by miały do niej dostęp jedynie osoby upoważnione.
          </li>
          <li>
            Podczas korzystania z serwisu Internetowego mogą być pobierane dodatkowe informacje, w szczególności:
            <ol type="a">
              <li>
                adres IP przypisany do urządzenia końcowego (np. telefon, tablet, komputer) Klienta lub zewnętrzny adres
                IP dostawcy Internetu, nazwa domeny, rodzaj przeglądarki, czas dostępu, typ systemu operacyjnego.
              </li>
              <li>
                W celu marketingu produktów własnych oraz poprawy usług od Użytkowników mogą być także gromadzone dane
                nawigacyjne, w tym informacje o linkach i odnośnikach, w które zdecydują się kliknąć, lub innych
                czynnościach podejmowanych w Serwisie na podstawie prawnie uzasadnionego interesu Administratora (art. 6
                ust. 1 lit. f RODO), polegający na ułatwieniu korzystania z usług świadczonych drogą elektroniczną oraz
                na poprawie funkcjonalności tych usług.
              </li>
            </ol>
          </li>
          <li>
            Przekazanie danych osobowych jest dobrowolne w związku ze świadczeniem usług za pośrednictwem Serwisu, z tym
            jednak zastrzeżeniem, że niepodanie określonych w formularzu danych uniemożliwi realizację tej usługi.
          </li>
        </ol>
      </li>

      <li class="h2">
        <h2>ISTOTNE TECHNIKI MARKETINGOWE</h2>
        <ol>
          <li>
            Administrator stosuje analizę statystyczną ruchu na stronie poprzez Google Analytics (Google Inc. z
            siedzibą w USA). Administrator przekazuje do operatora tej usługi jedynie anonimowe informacje statystyczne
            służące do pomiaru zachowań Użytkowników Serwisu. Usługa bazuje na wykorzystaniu ciasteczek w urządzeniu
            końcowym użytkownika.
            <a href="https://policies.google.com/technologies/partner-sites" target="_blank">Więcej informacji.</a>
          </li>
          <li>
            Administrator stosuje techniki remarketingowe pozwalające na dopasowanie przekazów reklamowych do zachowania
            użytkownika na stronie, co może dawać złudzenie, że dane osobowe użytkownika są wykorzystywane do jego
            śledzenia, jednak w praktyce nie dochodzi do przekazania żadnych danych osobowych od Administratora do
            operatorów reklam. Technologicznym warunkiem takich działań jest włączona obsługa plików cookie.
          </li>
          <li>
            Administrator korzysta z narzędzia Meta Pixel (Meta Platforms Inc. z siedzibą w USA). Narzędzie to bazuje na
            plikach cookie Facebooka przechowywanych w urządzeniu końcowym Użytkownika i umożliwia powiązanie
            Użytkownika korzystającego z Serwisu z jego kontem w serwisie Facebook. Administrator nie przekazuje od
            siebie żadnych dodatkowych danych osobowych firmie Meta/serwisowi Facebook.
          </li>
        </ol>
      </li>

      <li class="h2">
        <h2>INFORMACJA O PLIKACH COOKIES</h2>
        <ol>
          <li>
            Serwis korzysta z plików cookies.
          </li>
          <li>
            Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które
            przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron
            internetowych Serwisu. Cookies zazwyczaj zawierają nazwę, domenę internetową, z której pochodzą oraz czas
            przechowywania ich na urządzeniu końcowym.
          </li>
          <li>
            Wyróżnić można dwa rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies).
            <ol type="a">
              <li>
                „sesyjne” pliki cookies są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym
                Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania
                (przeglądarki internetowej),
              </li>
              <li>
                „stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w
                parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.
              </li>
            </ol>
          </li>
          <li>
            Pliki cookies wykorzystywane są w następujących celach:
            <ol type="a">
              <li>
                zapewniania sprawnego funkcjonowania Serwisu oraz dostosowania go do potrzeb Użytkowników,
              </li>
              <li>
                analiz oglądalności, w szczególności do anonimowych statystyk, pomagających zrozumieć sposób korzystania
                z Serwisu przez Użytkowników,
              </li>
              <li>
                realizacji celów określonych powyżej w części „Istotne techniki marketingowe”.
              </li>
            </ol>
          </li>
          <li>
            Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza
            przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany
            ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe jest także
            automatyczne blokowanie plików cookies. Szczegółowe informacje na ten temat zawiera pomoc lub dokumentacja
            przeglądarki internetowej.
          </li>
          <li>
            Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach
            internetowych Serwisu.
          </li>
          <li>
            Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu wykorzystywane mogą być również przez
            współpracujące z Administratorem Serwisu podmioty, w szczególności dotyczy to firm: Google (Google Inc. z
            siedzibą w USA), Meta (Meta Platforms Inc. z siedzibą w USA).
          </li>
        </ol>
      </li>

      <li class="h2">
        <h2>ZMIANY</h2>
        <ol>
          <li>
            W przypadku zmiany obowiązującej polityki prywatności, wprowadzone zostaną odpowiednie modyfikacje do
            powyższego zapisu.
          </li>
          <li>
            Pytania związane z Polityką Prywatności prosimy kierować na adres: physiomove.strug&#64;gmail.com
          </li>
          <li>
            Data ostatniej modyfikacji: 30.01.2025 r.
          </li>
        </ol>
      </li>
    </ol>
  </div>
</div>
