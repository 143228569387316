<nav id="navbar" class="navbar navbar-expand-lg fixed-top">
  <div class="container">
    <div class="navbar-translate" *ngIf="complex(); else brandOnly">
      <button id="navbar-toggler" type="button" class="navbar-toggler" aria-label="Toggle sidebar"
              (click)="openSidebar()">
        <fa-icon [icon]="faBars"></fa-icon>
      </button>
      <span class="navbar-brand">Fizjoterapia Strug</span>
    </div>
    <ng-template #brandOnly>
      <span class="navbar-brand">Fizjoterapia Strug</span>
    </ng-template>
    <div class="navbar-collapse" *ngIf="complex()">
      <div class="row navbar-nav ml-auto">
        <div class="col-md-3 nav-item">
          <div class="nav-link h-100 clickable" rel="tooltip" title="Rezerwacja" (click)="openBooksyDialog()">
            <div class="row align-items-center h-100">
              <div class="col-6 col-lg-4 text-end">
                <i>
                  <img ngSrc="assets/booksy.svg" alt="Logo Booksy" height="21" width="28" loading="eager">
                </i>
              </div>
              <div class="col-6 col-lg-8 ps-0">
                <span>Zarezerwuj</span>
                <img ngSrc="assets/booksy-attribution.jpeg" alt="Powered by Booksy" height="10" width="102"
                     loading="eager">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-35 nav-item">
          <a class="nav-link h-100" rel="tooltip" title="Telefon" href="tel:{{constants.PHONE_NUMBER}}">
            <div class="row align-items-center h-100">
              <div class="col-6 col-lg-4 text-end">
                <i>
                  <fa-icon [icon]="faPhone"></fa-icon>
                </i>
              </div>
              <div class="col-6 col-lg-8 ps-0">
                <span>{{ constants.PHONE_NUMBER_TEXT }}<br/>Umów wizytę</span>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-35 nav-item">
          <a class="nav-link h-100" rel="tooltip" title="Adres" href="{{constants.ADDRESS_LINK}}" target="_blank">
            <div class="row align-items-center h-100">
              <div class="col-md-2 text-end">
                <i>
                  <fa-icon [icon]="faMapLocationDot"></fa-icon>
                </i>
              </div>
              <div class="col-md-10">
                <div class="col">
                  <span>{{ constants.ADDRESS_SHORT_TEXT }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-15 nav-item">
          <div class="row align-items-center h-100">
            <div class="col-md-4">
              <a class="nav-link" rel="tooltip" title="Instagram" href="{{constants.INSTAGRAM_LINK}}" target="_blank">
                <i>
                  <fa-icon [icon]="faInstagram"></fa-icon>
                </i>
              </a>
            </div>
            <div class="col-md-4">
              <a class="nav-link" rel="tooltip" title="Facebook" href="{{constants.FACEBOOK_LINK}}" target="_blank">
                <i>
                  <fa-icon [icon]="faFacebookSquare"></fa-icon>
                </i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
