import {Component} from '@angular/core';

interface Service {
  imageName: string;
  alternativeText: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-services-section',
  templateUrl: './services-section.component.html',
  styleUrls: ['./services-section.component.scss']
})
export class ServicesSectionComponent {

  readonly firstRowServices: Array<Service> = [
    {
      imageName: 'terapia-manualna.webp',
      alternativeText: 'Ilustracja terapii manualnej',
      title: 'Terapia manualna',
      description: `Terapia manualna to metoda fizjoterapii skupiona na poprawie funkcji układu ruchu. Opiera się na
                    szczegółowym badaniu oraz leczeniu manualnym stawów, mięśni i więzadeł, aby złagodzić ból i poprawić
                    ruchomość.`
    },
    {
      imageName: 'igly.webp',
      alternativeText: 'Ilustacja suchego igłowania',
      title: 'Suche igłowanie',
      description: `Suche igłowanie to metoda fizjoterapeutyczna wykorzystująca sterylne igły do akupunktury, stosowane
                    w celu nakłuwania punktów spustowych i napiętych pasm mięśniowych. Pomaga w leczeniu bólu
                    mięśniowo-powięziowego i poprawie funkcji narządu ruchu.`
    },
    {
      imageName: 'powiez.webp',
      alternativeText: 'Ilustracja terapii mięśniowo-powięziowej',
      title: 'Terapia mięśniowo-powięziowa',
      description: `Terapia mięśniowo-powięziowa to metoda fizjoterapii, która łagodzi ból mięśni i stawów oraz poprawia
                    ruchomość tkanek. Dzięki masażowi powięziowemu organizm lepiej funkcjonuje, a z terapii mogą
                    korzystać pacjenci z różnymi dolegliwościami.`
    }
  ];
  readonly secondRowServices: Array<Service> = [
    {
      imageName: 'trening.webp',
      alternativeText: 'Ilustracja drabinki wykorzystywanej do treningu rehabilitacyjnego',
      title: 'Trening rehabilitacyjny',
      description: `Trening rehabilitacyjny łączy elementy fizjoterapii i treningu personalnego. Jest przeznaczony dla
                    osób wracających do formy sportowej po urazach lub operacjach, a także tych, którzy chcą poprawić
                    efektywność swoich treningów w sposób bezpieczny i kontrolowany.`
    },
    {
      imageName: 'nmdt.webp',
      alternativeText: 'Ilustracja diagnostyki nerwowo-mięśniowej',
      title: 'Diagnostyka nerwowo-mięśniowa',
      description: `To metoda pozwalająca zidentyfikować przyczyny bólu promieniującego, drętwienia kończyn i innych
                    dysfunkcji układu nerwowego. Skupia się na ocenie obwodowego układu nerwowego, by precyzyjnie
                    określić źródło problemu i zaplanować skuteczne leczenie.`
    },
    {
      imageName: 'masaz.webp',
      alternativeText: 'Ilustracja masażu klasycznego',
      title: 'Masaż klasyczny',
      description: `Masaż klasyczny to popularna technika relaksacyjna, która obejmuje takie zabiegi jak głaskanie,
                    rozcieranie, ugniatanie i oklepywanie. Działa na mięśnie, rozluźniając napięcie i poprawiając
                    krążenie krwi. Masaż kręgosłupa w tej metodzie wspomaga elastyczność mięśni i zwiększa zakres
                    ruchomości, a także łagodzi ból. Idealny dla osób borykających się z napięciami i dyskomfortem w
                    ciele.`
    }
  ]

}
