import {AfterViewInit, Component, Renderer2} from '@angular/core';
import {BooksyDialogComponent} from "./booksy-dialog/booksy-dialog.component";
import {SidebarService} from "./sidebar/sidebar.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MetaPixelService} from "../services/meta-pixel.service";
import {getApp} from 'firebase/app';
import {getDatabase, onValue, ref} from "firebase/database";

interface ToastData {
  readonly active: boolean;
  readonly title: string;
  readonly details: string;
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss'
})
export class HomePageComponent implements AfterViewInit {

  toastData: ToastData = {
    active: false,
    title: '',
    details: ''
  }

  constructor(private readonly sidebarService: SidebarService,
              private readonly modalService: NgbModal,
              private readonly pixelService: MetaPixelService,
              private readonly renderer: Renderer2) {
    const db = getDatabase(getApp());
    const homepageNotice = ref(db, 'homepageNotice');
    onValue(homepageNotice,
      snapshot => {
        this.toastData = snapshot.val();
      },
      error => {
        console.log(error);
      });
  }

  ngAfterViewInit(): void {
    window.addEventListener("scroll", this.reveal);
    window.addEventListener("scroll", () => this.sidebarService.closeSidebar());
    const footer = document.getElementById('footer') as HTMLElement;
    this.renderer.listen('window', 'scroll', () => {
      if (window.scrollY > 50) {
        footer.classList.add('opacity-0');
      } else {
        footer.classList.remove('opacity-0');
      }
    });
  }

  openBooksyDialog(): void {
    this.pixelService.trackScheduleInitiated();
    this.modalService.open(BooksyDialogComponent, {
      keyboard: false,
      beforeDismiss: () => false
    });
  }

  reveal() {
    document.querySelectorAll(".reveal")
      .forEach(e => {
        const elementTop = e.getBoundingClientRect().top;
        if (elementTop < window.innerHeight - 50) {
          e.classList.add("active");
        } else {
          e.classList.remove("active");
        }
      })
  }

}
