import {Component} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {MetaPixelService} from "../../services/meta-pixel.service";

interface CloseEvent {
  appointmentCompleted: boolean;
}

interface GoToUrlEvent {
  url: string;
}

@Component({
  selector: 'app-booksy-dialog',
  standalone: true,
  imports: [],
  templateUrl: './booksy-dialog.component.html',
  styleUrl: './booksy-dialog.component.scss'
})
export class BooksyDialogComponent {

  constructor(private readonly modal: NgbActiveModal,
              private readonly pixelService: MetaPixelService) {
    window.addEventListener('message',
      (event) => BooksyDialogComponent.handleMessage(this, event),
      true);
  }

  private static handleMessage(dialogInstance: BooksyDialogComponent, event: MessageEvent) {
    const closeEvent = event.data.events.close as CloseEvent;
    const goToUrlEvent = event.data.events.goToUrl as GoToUrlEvent;
    if (closeEvent) {
      if (closeEvent.appointmentCompleted) {
        dialogInstance.pixelService.trackSchedule();
      }
      dialogInstance.modal.close(closeEvent.appointmentCompleted);
    } else if (goToUrlEvent) {
      window.open(goToUrlEvent.url, '_blank')?.focus();
    }
  }

}
