import {Injectable} from '@angular/core';
import {getApp} from 'firebase/app';
import {addDoc, collection, DocumentReference, getFirestore} from 'firebase/firestore';

export interface EmailMessage {
  to: string;
  replyTo: string;
  subject: string;
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private readonly firestore = getFirestore(getApp());

  sendEmail(message: EmailMessage): Promise<DocumentReference> {
    const mailCollection = collection(this.firestore, 'mail');
    return addDoc(mailCollection, {
      to: [message.to],
      replyTo: message.replyTo,
      message: {
        subject: message.subject,
        text: message.text
      }
    });
  }

}
