import {Component} from '@angular/core';
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'app-toast-container',
  styleUrl: './toast-container.component.scss',
  template: `
    @for (toast of toastService.toasts; track toast) {
      <ngb-toast
        [header]="toast.header"
        [class]="toast.classname"
        [autohide]="true"
        [delay]="toast.delay"
        (hidden)="toastService.remove(toast)">
        {{ toast.message }}
      </ngb-toast>
    }
  `,
  host: {
    class: 'toast-container position-fixed end-0 p-5',
    style: 'z-index: 1001',
  }
})
export class ToastContainerComponent {

  constructor(public toastService: ToastService) {
  }

}
