<div class="section" id="o-mnie">
  <div class="container">
    <div class="text-center">
      <h1 class="title">O mnie</h1>
      <hr class="section-hr reveal fade-width"/>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <p class="description text-justify">
          Jestem fizjoterapeutą specjalizującym się w terapii manualnej, rehabilitacji ortopedycznej i fizjoterapii
          sportowej. Swoje doświadczenie zdobyłem, pracując zarówno ze sportowcami, jak i pacjentami indywidualnymi, co
          pozwala mi skutecznie pomagać w leczeniu urazów, bólu kręgosłupa oraz w rehabilitacji pooperacyjnej.
        </p>
        <p class="description text-justify">
          Jako magister fizjoterapii nieustannie rozwijam swoje umiejętności, uczestnicząc w specjalistycznych
          szkoleniach i korzystając z nowoczesnych technik leczenia. Oferuję kompleksowe podejście do problemów
          zdrowotnych, dostosowując metody pracy do indywidualnych potrzeb każdego pacjenta.
        </p>
        <p class="description text-justify">
          W moim gabinecie w Gliwicach możesz skorzystać z:
        </p>
        <ul>
          <li class="description text-justify">
            <b>rehabilitacji kręgosłupa</b> i masażu terapeutycznego pomagającego złagodzić ból pleców,
          </li>
          <li class="description text-justify">
            <b>rehabilitacji pooperacyjnej</b> wspomagającej szybki powrót do sprawności,
          </li>
          <li class="description text-justify">
            <b>fizjoterapii sportowej</b> pomagającej w leczeniu kontuzji i poprawie wydolności,
          </li>
          <li class="description text-justify">
            <b>fizjoterapii ortopedycznej</b> wspierającej leczenie urazów mięśni, stawów i kości.
          </li>
        </ul>
        <p class="description text-justify">
          Jeśli szukasz dobrego fizjoterapeuty w Gliwicach, który oferuje indywidualne podejście i skuteczne metody
          leczenia, zapraszam do kontaktu. Razem opracujemy plan, który pomoże Ci szybko odzyskać sprawność i komfort
          życia.
        </p>
        <p class="description text-justify">
          Pracuję z pacjentami przy:
        </p>
        <br/>
        <div>
          <br/><br/>
          <div ngbAccordion [closeOthers]="true">
            <div ngbAccordionItem *ngFor="let item of accordionItems">
              <div ngbAccordionHeader aria-level="1">
                <button ngbAccordionButton>
                  <b>{{ item.title }}</b>
                </button>
              </div>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <li *ngFor="let example of item.examples">
                      {{ example }}
                    </li>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mt-5 mt-lg-0 text-center">
        <img ngSrc="assets/o-mnie.webp" class="img-rounded" alt="Zdjęcie profilowe" height="660" width="440">
      </div>
    </div>
  </div>
</div>
