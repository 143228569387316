import {Component} from '@angular/core';
import {Section, SectionService} from "../../services/section.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  readonly sections: Array<Section>;

  constructor(private readonly sectionService: SectionService) {
    this.sections = sectionService.getSections();
  }

}
