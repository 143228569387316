import {Component} from '@angular/core';

interface AccordionItem {
  title: string;
  examples: string[];
}

@Component({
  selector: 'app-about-me-section',
  templateUrl: './about-me-section.component.html',
  styleUrls: ['./about-me-section.component.scss']
})
export class AboutMeSectionComponent {

  protected readonly accordionItems: Array<AccordionItem> = [
    {
      title: '- leczeniu bólu kręgosłupa,',
      examples: [
        'rehabilitacja dyskopatii,',
        'leczenie przepukliny kręgosłupa,',
        'fizjoterapia rwy kulszowej,',
        'leczenie bólu odcinka lędźwiowego, szyjnego i karku.'
      ]
    },
    {
      title: '- fizjoterapii bólu mięśni i stawów,',
      examples: [
        'terapia powięziowa w leczeniu bólu mięśni,',
        'rehabilitacja bólu stawów,',
        'leczenie cieśni nadgarstka i drętwienia palców,',
        'fizjoterapia bólu promieniującego (np. w nodze lub ręce).'
      ]
    },
    {
      title: '- rehabilitacji po artroskopii i operacjach stawów,',
      examples: [
        'rehabilitacja po artroskopii kolana, biodra i barku,',
        'fizjoterapia po operacji cieśni nadgarstka i kręgosłupa,',
        'rehabilitacja po endoprotezie stawu.'
      ]
    },
    {
      title: '- rehabilitacji sportowej i leczeniu kontuzji.',
      examples: [
        'rehabilitacja po skręceniu kostki lub stawu kolanowego,',
        'leczenie złamań i zwichnięć (np. zwichnięcie barku),',
        'fizjoterapia po zerwaniu więzadeł i mięśni.'
      ]
    }
  ]

}
