import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NavbarComponent} from './navbar/navbar.component';
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {FooterComponent} from './home-page/footer/footer.component';
import {SidebarComponent} from './home-page/sidebar/sidebar.component';
import {NgbAccordionModule, NgbCarousel, NgbSlide, NgbToast} from "@ng-bootstrap/ng-bootstrap";
import {NgOptimizedImage} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {AboutMeSectionComponent} from "./home-page/about-me-section/about-me-section.component";
import {FirstVisitSectionComponent} from "./home-page/first-visit-section/first-visit-section.component";
import {ServicesSectionComponent} from "./home-page/services-section/services-section.component";
import {GallerySectionComponent} from "./home-page/gallery-section/gallery-section.component";
import {ContactSectionComponent} from "./home-page/contact-section/contact-section.component";
import {ReviewsSectionComponent} from "./home-page/reviews-section/reviews-section.component";
import {FaqSectionComponent} from "./home-page/faq-section/faq-section.component";
import {EmailMessageSectionComponent} from "./home-page/email-message-section/email-message-section.component";
import {BooksyDialogComponent} from "./home-page/booksy-dialog/booksy-dialog.component";
import {HomePageComponent} from "./home-page/home-page.component";
import {PrivacyPolicyPageComponent} from "./privacy-policy-page/privacy-policy-page.component";
import {ToastContainerComponent} from "./toast/toast-container/toast-container.component";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    AboutMeSectionComponent,
    FirstVisitSectionComponent,
    ServicesSectionComponent,
    GallerySectionComponent,
    ContactSectionComponent,
    ReviewsSectionComponent,
    FaqSectionComponent,
    EmailMessageSectionComponent,
    HomePageComponent,
    PrivacyPolicyPageComponent,
    ToastContainerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    LeafletModule,
    NgbAccordionModule,
    NgbCarousel,
    NgbSlide,
    NgOptimizedImage,
    BooksyDialogComponent,
    ReactiveFormsModule,
    NgbToast
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}
