import {Component, OnInit} from '@angular/core';
import {NgbActiveOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {SidebarService} from "./sidebar.service";
import {ContactConstants} from "../../contact-constants";
import {Section, SectionService} from "../../services/section.service";
import {faAt, faMapLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare, faInstagram} from "@fortawesome/free-brands-svg-icons";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  protected readonly faPhone = faPhone;
  protected readonly faAt = faAt;
  protected readonly faMapLocationDot = faMapLocationDot;
  protected readonly faInstagram = faInstagram;
  protected readonly faFacebookSquare = faFacebookSquare;

  readonly constants = ContactConstants;
  readonly sections: Array<Section>;

  constructor(private readonly activeOffCanvas: NgbActiveOffcanvas,
              private readonly sidebarService: SidebarService,
              private readonly sectionService: SectionService) {
    this.sidebarService.updateRef(this.activeOffCanvas);
    this.sections = sectionService.getSections();
  }

  ngOnInit(): void {
  }

  dismiss(section: Section | null) {
    this.activeOffCanvas.dismiss();
    if (section != null) {
      setTimeout(() => window.location.href = '#' + section.id, 50);
    }
  }
}
