import {Component, input} from '@angular/core';
import {NgbModal, NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {SidebarComponent} from "../home-page/sidebar/sidebar.component";
import {ContactConstants} from "../contact-constants";
import {faBars, faMapLocationDot, faPhone} from "@fortawesome/free-solid-svg-icons";
import {faFacebookSquare, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {MetaPixelService} from "../services/meta-pixel.service";
import {BooksyDialogComponent} from "../home-page/booksy-dialog/booksy-dialog.component";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  readonly complex = input.required<boolean>();

  protected readonly faPhone = faPhone;
  protected readonly faMapLocationDot = faMapLocationDot;
  protected readonly faInstagram = faInstagram;
  protected readonly faFacebookSquare = faFacebookSquare;
  protected readonly faBars = faBars;

  readonly constants = ContactConstants;

  constructor(private readonly offCanvasService: NgbOffcanvas,
              private readonly modalService: NgbModal,
              private readonly pixelService: MetaPixelService) {
  }

  openSidebar() {
    this.offCanvasService.open(SidebarComponent, {
      panelClass: 'sidebar'
    });
  }

  openBooksyDialog() {
    this.pixelService.trackScheduleInitiated();
    this.modalService.open(BooksyDialogComponent, {
      keyboard: false,
      beforeDismiss: () => false
    });
  }

}
